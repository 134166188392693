var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    { staticClass: "main", staticStyle: { height: "100%" } },
    [
      _c(
        "Sider",
        {
          staticClass: "left-sider",
          attrs: {
            "hide-trigger": "",
            collapsible: "",
            width: 200,
            "collapsed-width": 64
          },
          model: {
            value: _vm.collapsed,
            callback: function($$v) {
              _vm.collapsed = $$v
            },
            expression: "collapsed"
          }
        },
        [
          _c(
            "side-menu",
            {
              ref: "sideMenu",
              attrs: {
                accordion: "",
                "active-name": _vm.$route.name,
                collapsed: _vm.collapsed,
                "menu-list": _vm.menuList
              },
              on: { "on-select": _vm.turnToPage }
            },
            [
              _c(
                "div",
                {
                  staticClass: "logo-con",
                  style: {
                    overflow: "hidden",
                    background: _vm.$store.state.app.themeColor
                  }
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.collapsed,
                        expression: "!collapsed"
                      }
                    ],
                    key: "max-logo",
                    attrs: { src: _vm.maxLogo }
                  }),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.collapsed,
                        expression: "collapsed"
                      }
                    ],
                    key: "min-logo",
                    attrs: { src: _vm.minLogo }
                  })
                ]
              )
            ]
          )
        ],
        1
      ),
      _c(
        "Layout",
        [
          _c(
            "Header",
            { staticClass: "header-con" },
            [
              _c(
                "header-bar",
                {
                  attrs: { collapsed: _vm.collapsed },
                  on: { "on-coll-change": _vm.handleCollapsedChange }
                },
                [
                  _c("user", { attrs: { "user-avatar": _vm.userAvatar } }),
                  _c("theme", { staticStyle: { margin: "0 5px" } }),
                  _c("fullscreen", {
                    staticStyle: { "margin-right": "10px" },
                    model: {
                      value: _vm.isFullscreen,
                      callback: function($$v) {
                        _vm.isFullscreen = $$v
                      },
                      expression: "isFullscreen"
                    }
                  }),
                  _c("msg")
                ],
                1
              )
            ],
            1
          ),
          _c(
            "Content",
            { staticClass: "main-content-con" },
            [
              _c(
                "Layout",
                { staticClass: "main-layout-con" },
                [
                  _c(
                    "div",
                    { staticClass: "tag-nav-wrapper" },
                    [
                      _c("tags-nav", {
                        attrs: { value: _vm.$route, list: _vm.tagNavList },
                        on: {
                          input: _vm.handleClick,
                          "on-close": _vm.handleCloseTag
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "Content",
                    { staticClass: "content-wrapper" },
                    [
                      _c(
                        "keep-alive",
                        { attrs: { include: _vm.cacheList } },
                        [
                          _c("router-view", {
                            staticStyle: {
                              height: "100%",
                              width: "100%",
                              "box-sizing": "border-box"
                            }
                          })
                        ],
                        1
                      ),
                      _c("ABackTop", {
                        attrs: {
                          height: 100,
                          bottom: 80,
                          right: 50,
                          container: ".content-wrapper"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }