import Main from '_c/main/Main'
import store from '@/store'
import ParentView from '_c/parentView/ParentView'
/**
 * 菜单树和路由树diff 构建新的路由树
 * @param dynamicRouter
 * @param menuTree
 * @param level
 * @returns {[]}
 */
export function diffMenuTree (dynamicRouter, menuTree, level = 0) {
    const routerArray = []
    // 对菜单树进行遍历, 找出对应的路由组件对象，动态加载到路由数组里
    menuTree.forEach((menuItem, index) => {
        // 只有一个页面的时候
        if (level === 0 &&
            (!menuItem.children ||
                !menuItem.children.length ||
                !menuItem.children[0].isMenu
            )) {
            const route = dynamicRouter.find((routeItem) => {
                return routeItem.name === menuItem.code
            })
            if (!route) {
                return
            }
            const menu = {
                path: `/${menuItem.code}`,
                component: Main,
                meta: {
                    ...route.meta,
                    ...menuItem,
                    icon: menuItem.icon || route.meta.icon || 'ios-stats'
                },
                children: []
            }
            // 如果是首页
            if (store.state.auth && store.state.auth.indexPage === menuItem.code) {
                menu.path = ''
            }
            menu.children.push({
                ...route,
                meta: {
                    auth: menuItem.children.reduce((result, authItem) => {
                        Object.assign(result, {
                            [`${authItem.code}`]: {
                                name: authItem.name,
                                icon: authItem.icon,
                                uri: authItem.uri
                            }
                        })
                        return result
                    }, {}),
                    title: typeof route.meta.title === 'function' ? route.meta.title : `${menuItem.name}`,
                    icon: menuItem.icon || route.meta.icon || 'ios-stats'
                }
            })
            return routerArray.push(menu)
        } else if (menuItem.children && menuItem.children.length && menuItem.children[0].isMenu) { // 多级页面
            const menu = {
                path: `/${menuItem.code}`,
                name: `${menuItem.code}`,
                meta: {
                    icon: menuItem.icon || 'ios-stats',
                    title: `${menuItem.name}`,
                    showAlways: true
                },
                component: level > 0 ? ParentView : Main, // 根据层级
                children: []
            }
            menu.children = diffMenuTree(dynamicRouter, menuItem.children, level + 1)
            return routerArray.push(menu)
        } else if (menuItem.isMenu && (!menuItem.children ||
            !menuItem.children.length ||
            !menuItem.children[0].isMenu
        )) {
            const route = dynamicRouter.find((routeItem) => {
                return routeItem.name === menuItem.code
            })
            if (!route) {
                return
            }
            return routerArray.push({
                path: `/${menuItem.code}`,
                name: `${menuItem.code}`,
                meta: {
                    hideInBread: menuItem.hideInBread,
                    hideInMenu: menuItem.hideInMenu,
                    // 格式化权限数组
                    auth: menuItem.children.reduce((result, authItem) => {
                        Object.assign(result, {
                            [`${authItem.code}`]: {
                                name: authItem.name,
                                icon: authItem.icon,
                                uri: authItem.uri
                            }
                        })
                        return result
                    }, {}),
                    icon: menuItem.icon || route.meta.icon || 'ios-stats',
                    title: typeof route.meta.title === 'function' ? route.meta.title : `${menuItem.name}`,
                },
                component: route.component,
            })
        }
    })
    return routerArray
}
