var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dropdown",
    {
      ref: "dropdown",
      class: _vm.hideTitle ? "" : "collased-menu-dropdown",
      attrs: { transfer: _vm.hideTitle, placement: _vm.placement },
      on: { "on-click": _vm.handleClick }
    },
    [
      _c(
        "a",
        {
          staticClass: "drop-menu-a",
          style: { textAlign: !_vm.hideTitle ? "left" : "" },
          attrs: { type: "text" },
          on: {
            mouseover: function($event) {
              return _vm.handleMousemove($event, _vm.children)
            }
          }
        },
        [
          _c("Icon", {
            attrs: {
              size: _vm.rootIconSize,
              color: "#fff",
              type: _vm.parentItem.icon
            }
          }),
          !_vm.hideTitle
            ? _c("span", { staticClass: "menu-title" }, [
                _vm._v(_vm._s(_vm.showTitle(_vm.parentItem)))
              ])
            : _vm._e(),
          !_vm.hideTitle
            ? _c("Icon", {
                staticStyle: { float: "right" },
                attrs: { type: "ios-arrow-forward", size: 16 }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "DropdownMenu",
        { ref: "dropdown", attrs: { slot: "list" }, slot: "list" },
        [
          _vm._l(_vm.children, function(child) {
            return [
              _vm.showChildren(child)
                ? _c("collapsed-menu", {
                    key: "drop-" + child.name,
                    attrs: {
                      "icon-size": _vm.iconSize,
                      "parent-item": child,
                      theme: _vm.theme
                    }
                  })
                : _c(
                    "DropdownItem",
                    { key: "drop-" + child.name, attrs: { name: child.name } },
                    [
                      _c("Icon", {
                        attrs: { size: _vm.iconSize, type: child.icon }
                      }),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(_vm._s(_vm.showTitle(child)))
                      ])
                    ],
                    1
                  )
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }