import { render, staticRenderFns } from "./MerchantItem.vue?vue&type=template&id=92dd0226&scoped=true&"
import script from "./MerchantItem.vue?vue&type=script&lang=js&"
export * from "./MerchantItem.vue?vue&type=script&lang=js&"
import style0 from "./MerchantItem.vue?vue&type=style&index=0&id=92dd0226&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92dd0226",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\chenanlian\\cdz2\\cms-saas\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('92dd0226')) {
      api.createRecord('92dd0226', component.options)
    } else {
      api.reload('92dd0226', component.options)
    }
    module.hot.accept("./MerchantItem.vue?vue&type=template&id=92dd0226&scoped=true&", function () {
      api.rerender('92dd0226', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/merchant/view/merchantList/MerchantItem.vue"
export default component.exports