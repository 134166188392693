var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Submenu",
    { attrs: { name: "" + _vm.parentName } },
    [
      _c(
        "template",
        { slot: "title" },
        [
          _c("Icon", { attrs: { type: _vm.parentItem.icon || "" } }),
          _c("span", [_vm._v(_vm._s(_vm.showTitle(_vm.parentItem)))])
        ],
        1
      ),
      _vm._l(_vm.children, function(item) {
        return [
          item.children && item.children.length === 1
            ? [
                _vm.showChildren(item)
                  ? _c("side-menu-item", {
                      key: "menu-" + item.name,
                      attrs: { "parent-item": item }
                    })
                  : _c(
                      "menu-item",
                      {
                        key: "menu-" + item.children[0].name,
                        attrs: { name: _vm.getNameOrHref(item, true) }
                      },
                      [
                        _c("Icon", {
                          attrs: { type: item.children[0].icon || "" }
                        }),
                        _c("span", [
                          _vm._v(_vm._s(_vm.showTitle(item.children[0])))
                        ])
                      ],
                      1
                    )
              ]
            : [
                _vm.showChildren(item)
                  ? _c("side-menu-item", {
                      key: "menu-" + item.name,
                      attrs: { "parent-item": item }
                    })
                  : _c(
                      "menu-item",
                      {
                        key: "menu-" + item.name,
                        attrs: { name: _vm.getNameOrHref(item) }
                      },
                      [
                        _c("Icon", { attrs: { type: item.icon || "" } }),
                        _c("span", [_vm._v(_vm._s(_vm.showTitle(item)))])
                      ],
                      1
                    )
              ]
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }