<template>
    <div>
        <Card v-for="item in merchantList"
              :key="item.id"
              :class="{'merchant-item': item.id === $store.state.auth.merchantId}"
              style="margin-bottom: 10px;cursor: pointer;"
              @click.native="handleSelectMerchant(item)">{{item.name}}
        </Card>
    </div>
</template>

<script>
    export default {
        name: 'MerchantItem',
        computed: {
            merchantList () {
                return this.$store.state.user.merchantList
            }
        },
        methods: {
            handleSelectMerchant (merchantItem) {
                if (merchantItem.id === this.$store.state.auth.merchantId) {
                    return
                }
                this.$store.dispatch('setMerchantId', merchantItem.id)
                this.$store.dispatch('getMenu')
                this.$emit('on-select')
            }
        }
    }
</script>

<style scoped>
    .merchant-item{
        background-color: #94d5f6;
        color: white;
    }
</style>
