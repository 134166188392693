var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Dropdown",
        {
          attrs: { trigger: "click" },
          on: { "on-click": _vm.handleSelectTheme }
        },
        [
          _c(
            "a",
            { attrs: { href: "javascript:void(0)" } },
            [
              _vm._v(" 主题 "),
              _c("Icon", { attrs: { size: 18, type: "md-arrow-dropdown" } })
            ],
            1
          ),
          _c(
            "DropdownMenu",
            { attrs: { slot: "list" }, slot: "list" },
            _vm._l(_vm.$options.themeEnum, function(item) {
              return _c(
                "DropdownItem",
                { key: item.key, attrs: { name: item.key } },
                [_vm._v(_vm._s(item.value))]
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }