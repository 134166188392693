import {
    getBreadCrumbList,
    getHomeRoute,
    getMenuByRouter,
    getNextRoute,
    getRouteTitleHandled,
    getTagNavListFromLocalstorage,
    routeEqual,
    routeHasExist,
    setTagNavListInLocalstorage,
    cacheStorage
} from '@/utils'
import dynamicRoutes from '_platform/router/dynamicRoutes'
import {
    diffMenuTree
} from '@/utils/menuTree'
import router from '_platform/router'
import Vue from 'vue'
import http from '@/api'
// 动态路由数组
let dynamicRouterArray = null
/**
 * 权限 store模块
 */
export default {
    state: {
        avatarImgPath: '',
        indexPage: process.env.VUE_APP_INDEX_PAGE,
        breadCrumbList: [],
        tagNavList: [],
        homeRoute: {},
        token: cacheStorage.getItem(process.env.VUE_APP_AUTH_TOKEN),
        // 当前用户信息
        user: cacheStorage.getItem(process.env.VUE_APP_USER) ? JSON.parse(cacheStorage.getItem(process.env.VUE_APP_USER)) : null,
        // 平台管理的默认商家id为0， 在商家管理端需要真实的id
        merchantId: 0,
        // 是否加载完菜单
        prepare: false,
        //小程序文章编辑
        row: {}
    },
    getters: {
        menuList: (state) => {
            return state.prepare ? getMenuByRouter(dynamicRouterArray) : []
        },
    },
    mutations: {
        //存放小程序文章编辑内容
        print(state, row) {
            state.row = row
        },
        // 设置面包屑
        setBreadCrumb(state, route) {
            state.breadCrumbList = getBreadCrumbList(route, state.homeRoute)
        },
        // 设置导航菜单
        setTagNavList(state, list) {
            let tagList = []
            if (list) {
                tagList = [...list]
            } else {
                tagList = getTagNavListFromLocalstorage() || []
            }
            if (tagList[0] && tagList[0].name !== state.indexPage) tagList.shift()
            let homeTagIndex = tagList.findIndex(item => item.name === state.indexPage)
            if (homeTagIndex > 0) {
                let homeTag = tagList.splice(homeTagIndex, 1)[0]
                tagList.unshift(homeTag)
            }
            state.tagNavList = tagList
            setTagNavListInLocalstorage([...tagList])
        },
        // 关闭标签页
        closeTag(state, route) {
            let tag = state.tagNavList.filter(item => routeEqual(item, route))
            route = tag[0] ? tag[0] : null
            if (!route) return
            const nextRoute = getNextRoute(state.tagNavList, route)
            state.tagNavList = state.tagNavList.filter(item => {
                return !routeEqual(item, route)
            })
            router.push(nextRoute)
        },
        // 添加标签页面
        addTag(state, {
            route,
            type = 'unshift'
        }) {
            let router = getRouteTitleHandled(route)
            if (!routeHasExist(state.tagNavList, router)) {
                if (type === 'push') {
                    state.tagNavList.push(router)
                } else {
                    if (router.name === state.indexPage) {
                        state.tagNavList.unshift(router)
                    } else {
                        state.tagNavList.splice(1, 0, router)
                    }
                }
                setTagNavListInLocalstorage([...state.tagNavList])
            }
        },
        // 获取权限菜单
        getMenu(state) {
            state.prepare = false
            if (state.token) {
                // 全局loading
                Vue.prototype.$Spin.show({
                    render: (h) => {
                        return h('div', [
                            h('Icon', {
                                'class': 'demo-spin-icon-load',
                                props: {
                                    type: 'ios-loading',
                                    size: 25
                                }
                            }),
                            h('div', '页面加载中')
                        ])
                    }
                })
                http.get(`/server/v1/Merchant/Auth/user/resource`, {
                    params: {
                        refresh: true,
                        type: 0
                    }
                }).then((response) => {
                    const {
                        code,
                        data
                    } = response
                    if (code === 1) {
                        if (!data.resource || !data.resource.length) {
                            state.prepare = true
                            router.replace({
                                name: '404'
                            })
                            Vue.prototype.$Spin.hide()
                        } else {
                            dynamicRouterArray = diffMenuTree(dynamicRoutes, data.resource)
                            router.addRoutes(dynamicRouterArray)
                            state.homeRoute = getHomeRoute(dynamicRouterArray, state.indexPage)
                            state.prepare = true
                            window.setTimeout(() => {
                                Vue.prototype.$Spin.hide()
                            }, 500)
                        }
                    }
                }, err => {
                    Vue.prototype.$Spin.hide()
                    cacheStorage.clear()
                    const {
                        status,
                        data
                    } = err.response
                    if (status === 401) {
                        router.replace({
                            name: '401'
                        })
                    } else {
                        Vue.prototype.$Notice.error({
                            title: '错误提示',
                            desc: data.msg
                        })
                    }
                })
            }
        },
        setToken(state, token) {
            state.token = token
        },
        setUser(state, user) {
            state.user = user
        },
        /**
         * 登出
         * @param state
         */
        layout(state) {
            // 清除缓存， 认证token,用户信息,然后跳转到登录页面
            cacheStorage.clear()
            state.token = null
            state.user = null
            router.push({
                name: 'login'
            })
        },
    },
    actions: {
        getMenu(context) {
            context.commit('getMenu')
        },
        setToken(context, token) {
            context.commit('setToken', token)
            cacheStorage.setItem(process.env.VUE_APP_AUTH_TOKEN, token)
        },
        setUser(context, user) {
            context.commit('setUser', user)
            cacheStorage.setItem(process.env.VUE_APP_USER, JSON.stringify(user))
        },
        logout(context) {
            context.commit('layout')
        },
    }
}
