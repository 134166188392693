var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        "margin-right": "14px",
        "line-height": "30px",
        "margin-top": "18px",
        cursor: "pointer"
      },
      on: { click: _vm.goMessge }
    },
    [
      _c(
        "Badge",
        { attrs: { count: _vm.msgCount, "overflow-count": "99" } },
        [
          _c("Icon", {
            staticStyle: { "font-weight": "bold" },
            attrs: { size: "28", type: "ios-mail-outline" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }