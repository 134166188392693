<template>
  <Modal v-model="showDialog" width="500">
    <p slot="header">
      <Icon type="ios-information-circle"></Icon>
      <span>商家列表</span>
    </p>
    <merchant-item></merchant-item>
    <div slot="footer" style="display: flex;">
      <Button type="primary" @click="handleCloseDialog" style="margin-left: auto;">
        关闭
      </Button>
    </div>
  </Modal>
</template>

<script>
import MerchantItem from '_merchant/view/merchantList/MerchantItem'
import dialogMixin from '_m/dialog.mixin'
export default {
  name: 'MerchantListDialog',
  components: {
    MerchantItem
  },
  mixins: [dialogMixin],
  methods: {
    handleCloseDialog () {
      this.showDialog = false
      //   location.reload()
      this.$router.push({
        path: '/home'
      })
    }
  }
}
</script>

<style scoped>
</style>
