<template>
  <div class="header-bar">
    <side-trigger :collapsed="collapsed" icon="md-menu" @on-change="handleCollpasedChange"></side-trigger>
    <custom-bread-crumb show-icon style="margin-left: 30px;" :list="breadCrumbList"></custom-bread-crumb>
    <div class="custom-content-con">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import SideTrigger from './sideTrigger/SideTrigger'
import CustomBreadCrumb from './customBreadCrumb/CustomBreadCrumb'
export default {
  name: 'HeaderBar',
  components: {
    SideTrigger,
    CustomBreadCrumb
  },
  props: {
    collapsed: Boolean
  },
  computed: {
    breadCrumbList () {
      return this.$store.state.auth.breadCrumbList
    }
  },
  methods: {
    handleCollpasedChange (state) {
      this.$emit('on-coll-change', state)
    }
  }
}
</script>
<style lang="less" scoped>
  .header-bar{
    width: 100%;
    height: 100%;
    position: relative;
    .custom-content-con{
      float: right;
      height: auto;
      padding-right: 20px;
      line-height: 64px;
      & > *{
        float: right;
      }
    }
  }
</style>
