<template>
  <div>
    <Dropdown trigger="click" @on-click="handleSelectTheme">
      <a href="javascript:void(0)">
        主题
        <Icon :size="18" type="md-arrow-dropdown" />
      </a>
      <DropdownMenu slot="list">
        <DropdownItem v-for="item in $options.themeEnum" :name="item.key" :key="item.key">{{ item.value }}</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  </div>
</template>

<script>
import { themeEnum } from '_e/index'

export default {
  name: 'Theme',
  themeEnum,
  methods: {
    handleSelectTheme (theme) {
      this.$store.dispatch('setTheme', theme)
    }
  }
}
</script>
