var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-avatar-dropdown" },
    [
      _c("merchant-dialog", {
        model: {
          value: _vm.showDialog,
          callback: function($$v) {
            _vm.showDialog = $$v
          },
          expression: "showDialog"
        }
      }),
      _c(
        "Dropdown",
        { on: { "on-click": _vm.handleClick } },
        [
          _c("Badge", [_c("Avatar", { attrs: { src: _vm.userAvatar } })], 1),
          _c("Icon", { attrs: { size: 18, type: "md-arrow-dropdown" } }),
          _c(
            "DropdownMenu",
            { attrs: { slot: "list" }, slot: "list" },
            [
              _c("DropdownItem", { attrs: { name: "logout" } }, [
                _vm._v("退出登录")
              ]),
              !_vm.isPlatform
                ? _c("DropdownItem", { attrs: { name: "merchantList" } }, [
                    _vm._v("商家列表")
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }