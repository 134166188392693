export default [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'Login - 登录',
      hideInMenu: true
    },
    component: () => import('_platform/view/login/Login.vue')
  },
  {
    path: '/messagePage',
    meta: {
      title: '消息'
    },
    component: () => import('_c/main/Main'),
    children: [
        {
            path: '/message',
            name: 'message',
            meta: {
                icon: 'md-notifications',
                title: '消息中心'
            },
            component: () => import('_merchant/view/message/Message.vue')
        },
    ]
  },
  {
    path: '/404',
    name: '404',
    meta: {
      title: '页面没找到',
      hideInMenu: true
    },
    component: () => import('_c/errorPage/404')
  },
  {
      path: '/401',
      name: '401',
      meta: {
          title: '无权限访问',
          hideInMenu: true
      },
      component: () => import('_c/errorPage/401')
  },
  {
      path: '/500',
      name: '500',
      meta: {
          title: '服务器错误',
          hideInMenu: true
      },
      component: () => import('_c/errorPage/500')
  }
]
