/**
 * 判断当前模式
 *  用于两个系统的公共部门
 */
import { sysTypeEnum } from '_e/index'
export default {
    computed: {
        isPlatform () {
            return sysTypeEnum.PLATFORM.key === process.env.VUE_APP_MODE
        }
    },
}
