var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.merchantList, function(item) {
      return _c(
        "Card",
        {
          key: item.id,
          class: {
            "merchant-item": item.id === _vm.$store.state.auth.merchantId
          },
          staticStyle: { "margin-bottom": "10px", cursor: "pointer" },
          nativeOn: {
            click: function($event) {
              return _vm.handleSelectMerchant(item)
            }
          }
        },
        [_vm._v(_vm._s(item.name) + " ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }