var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "side-menu-wrapper" },
    [
      _vm._t("default"),
      _c(
        "Menu",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.collapsed,
              expression: "!collapsed"
            }
          ],
          ref: "menu",
          staticStyle: { height: "100%" },
          attrs: {
            "active-name": _vm.activeName,
            "open-names": _vm.openedNames,
            accordion: _vm.accordion,
            theme: _vm.theme,
            width: "auto"
          },
          on: { "on-select": _vm.handleSelect }
        },
        [
          _vm._l(_vm.menuList, function(item, index) {
            return [
              item.children && item.children.length === 1
                ? [
                    _vm.showChildren(item)
                      ? _c("side-menu-item", {
                          key: "menu-" + index,
                          attrs: { "parent-item": item }
                        })
                      : _c(
                          "menu-item",
                          {
                            key: "menu-" + item.children[0].name,
                            attrs: { name: _vm.getNameOrHref(item, true) }
                          },
                          [
                            _c("Icon", {
                              attrs: { type: item.children[0].icon || "" }
                            }),
                            _c("span", [
                              _vm._v(_vm._s(_vm.showTitle(item.children[0])))
                            ])
                          ],
                          1
                        )
                  ]
                : [
                    _vm.showChildren(item)
                      ? _c("side-menu-item", {
                          key: "menu-" + index,
                          attrs: { "parent-item": item }
                        })
                      : _c(
                          "menu-item",
                          {
                            key: "menu-" + index,
                            attrs: { name: _vm.getNameOrHref(item) }
                          },
                          [
                            _c("Icon", { attrs: { type: item.icon || "" } }),
                            _c("span", [_vm._v(_vm._s(_vm.showTitle(item)))])
                          ],
                          1
                        )
                  ]
            ]
          })
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.collapsed,
              expression: "collapsed"
            }
          ],
          staticClass: "menu-collapsed",
          attrs: { list: _vm.menuList }
        },
        [
          _vm._l(_vm.menuList, function(item, index) {
            return [
              item.children &&
              (item.children.length > 1 || item.meta.showAlways)
                ? _c("collapsed-menu", {
                    key: "drop-menu-" + index,
                    attrs: {
                      "hide-title": "",
                      "root-icon-size": _vm.rootIconSize,
                      "icon-size": _vm.iconSize,
                      theme: _vm.theme,
                      "parent-item": item
                    },
                    on: { "on-click": _vm.handleSelect }
                  })
                : _c(
                    "Tooltip",
                    {
                      key: "drop-menu-" + index,
                      attrs: {
                        transfer: "",
                        content: _vm.showTitle(
                          item.children && item.children[0]
                            ? item.children[0]
                            : item
                        ),
                        placement: "right"
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "drop-menu-a",
                          style: { textAlign: "center" },
                          on: {
                            click: function($event) {
                              _vm.handleSelect(_vm.getNameOrHref(item, true))
                            }
                          }
                        },
                        [
                          _c("Icon", {
                            attrs: {
                              size: _vm.rootIconSize,
                              color: "#fff",
                              type:
                                item.icon ||
                                (item.children && item.children[0].icon)
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
            ]
          })
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }