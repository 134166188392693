var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tags-nav" }, [
    _c(
      "div",
      { staticClass: "close-con" },
      [
        _c(
          "Dropdown",
          {
            staticStyle: { "margin-top": "7px" },
            attrs: { transfer: "" },
            on: { "on-click": _vm.handleTagsOption }
          },
          [
            _c(
              "Button",
              { attrs: { size: "small", type: "text" } },
              [
                _c("Icon", {
                  attrs: { size: 18, type: "ios-close-circle-outline" }
                })
              ],
              1
            ),
            _c(
              "DropdownMenu",
              { attrs: { slot: "list" }, slot: "list" },
              [
                _c("DropdownItem", { attrs: { name: "close-all" } }, [
                  _vm._v("关闭所有")
                ]),
                _c("DropdownItem", { attrs: { name: "close-others" } }, [
                  _vm._v("关闭其他")
                ])
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "ul",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible"
          }
        ],
        staticClass: "contextmenu",
        style: {
          left: _vm.contextMenuLeft + "px",
          top: _vm.contextMenuTop + "px"
        }
      },
      _vm._l(_vm.menuList, function(item, key) {
        return _c(
          "li",
          {
            key: key,
            on: {
              click: function($event) {
                return _vm.handleTagsOption(key)
              }
            }
          },
          [_vm._v(_vm._s(item))]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "btn-con left-btn" },
      [
        _c(
          "Button",
          {
            attrs: { type: "text" },
            on: {
              click: function($event) {
                return _vm.handleScroll(240)
              }
            }
          },
          [_c("Icon", { attrs: { size: 18, type: "ios-arrow-back" } })],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "btn-con right-btn" },
      [
        _c(
          "Button",
          {
            attrs: { type: "text" },
            on: {
              click: function($event) {
                return _vm.handleScroll(-240)
              }
            }
          },
          [_c("Icon", { attrs: { size: 18, type: "ios-arrow-forward" } })],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      {
        ref: "scrollOuter",
        staticClass: "scroll-outer",
        on: { DOMMouseScroll: _vm.handlescroll, mousewheel: _vm.handlescroll }
      },
      [
        _c(
          "div",
          {
            ref: "scrollBody",
            staticClass: "scroll-body",
            style: { left: _vm.tagBodyLeft + "px" }
          },
          [
            _c(
              "transition-group",
              { attrs: { name: "taglist-moving-animation" } },
              _vm._l(_vm.list, function(item, index) {
                return _c(
                  "Tag",
                  {
                    key: "tag-nav-" + index,
                    ref: "tagsPageOpened",
                    refInFor: true,
                    attrs: {
                      type: "dot",
                      name: item.name,
                      "data-route-item": item,
                      closable: item.name !== _vm.$store.state.auth.indexPage,
                      color: _vm.isCurrentTag(item) ? "primary" : "default"
                    },
                    on: {
                      "on-close": function($event) {
                        return _vm.handleClose(item)
                      }
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.handleClick(item)
                      },
                      contextmenu: function($event) {
                        $event.preventDefault()
                        return _vm.contextMenu(item, $event)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.showTitleInside(item)))]
                )
              }),
              1
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }