var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes, style: _vm.styles, on: { click: _vm.back } },
    [
      _vm._t("default", [
        _c("div", { class: _vm.innerClasses }, [
          _c("i", { staticClass: "ivu-icon ivu-icon-ios-arrow-up" })
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }