var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-bar" },
    [
      _c("side-trigger", {
        attrs: { collapsed: _vm.collapsed, icon: "md-menu" },
        on: { "on-change": _vm.handleCollpasedChange }
      }),
      _c("custom-bread-crumb", {
        staticStyle: { "margin-left": "30px" },
        attrs: { "show-icon": "", list: _vm.breadCrumbList }
      }),
      _c("div", { staticClass: "custom-content-con" }, [_vm._t("default")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }