var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: { width: "500" },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "p",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("Icon", { attrs: { type: "ios-information-circle" } }),
          _c("span", [_vm._v("商家列表")])
        ],
        1
      ),
      _c("merchant-item"),
      _c(
        "div",
        {
          staticStyle: { display: "flex" },
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "Button",
            {
              staticStyle: { "margin-left": "auto" },
              attrs: { type: "primary" },
              on: { click: _vm.handleCloseDialog }
            },
            [_vm._v(" 关闭 ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }