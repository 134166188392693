var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-bread-crumb" },
    [
      _c(
        "Breadcrumb",
        { style: { fontSize: _vm.fontSize + "px" } },
        _vm._l(_vm.list, function(item) {
          return _c(
            "BreadcrumbItem",
            { key: "bread-crumb-" + item.name, attrs: { to: item.to } },
            [
              _c("Icon", {
                staticStyle: { "margin-right": "4px" },
                attrs: { type: item.icon || "" }
              }),
              _vm._v(" " + _vm._s(_vm.showTitle(item)) + " ")
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }