import { render, staticRenderFns } from "./SideTrigger.vue?vue&type=template&id=a0970972&scoped=true&"
import script from "./SideTrigger.vue?vue&type=script&lang=js&"
export * from "./SideTrigger.vue?vue&type=script&lang=js&"
import style0 from "./SideTrigger.vue?vue&type=style&index=0&id=a0970972&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0970972",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\chenanlian\\cdz2\\cms-saas\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a0970972')) {
      api.createRecord('a0970972', component.options)
    } else {
      api.reload('a0970972', component.options)
    }
    module.hot.accept("./SideTrigger.vue?vue&type=template&id=a0970972&scoped=true&", function () {
      api.rerender('a0970972', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/main/components/headerBar/sideTrigger/SideTrigger.vue"
export default component.exports