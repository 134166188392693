var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showFullScreenBtn
    ? _c(
        "div",
        { staticClass: "full-screen-btn-con" },
        [
          _c(
            "Tooltip",
            {
              attrs: {
                content: _vm.value ? "退出全屏" : "全屏",
                placement: "bottom"
              }
            },
            [
              _c("Icon", {
                attrs: {
                  type: _vm.value ? "md-contract" : "md-expand",
                  size: 23
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.handleChange($event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }