<template>
    <div style="margin-right: 14px;line-height: 30px; margin-top: 18px;cursor:pointer;" @click="goMessge">
        <Badge :count="msgCount" overflow-count="99">
            <Icon style="font-weight: bold;" size="28" type="ios-mail-outline" />
        </Badge>
    </div>
</template>

<script>
let waitTotalTimer
export default {
  name: 'Msg',
  data() {
      return {
          msgCount: 0,
      }
  },
  created() {
    this.getMsgCount()
  },
  methods: {
    getMsgCount () {
        this.$http.get(`/portal/v1/biz-station-msg/m/countByUser`, {
            params: {
                isRead: 0
            }
        }).then((res) => {
            const { code, msg, data } = res
            if (code === 1) {
                this.msgCount = data
                waitTotalTimer && clearTimeout(waitTotalTimer)
                waitTotalTimer = setTimeout(() => {
                    this.getMsgCount()
                    waitTotalTimer = null
                }, 30000)
            } else {
                this.$Notice.error({
                    title: `接口报错:code=${code}`,
                    desc: msg
                })
            }
        })
    },
    goMessge () {
        this.$router.push({
            path: '/message'
        })
    }
  }
}
</script>
