import Vue from 'vue'
import Router from 'vue-router'
import staticRoutes from './staticRoutes'
import iView from 'view-design'
import store from '@/store'
Vue.use(Router)
const router = new Router({
  routes: staticRoutes,
  mode: 'hash'
})

router.beforeEach((to, from, next) => {
  iView.LoadingBar.start()
  // 如果跳转到登录页面
  if (to.name === 'login' || to.path === '/login') {
    return next()
  } else if (!store.state.auth.token) {
    return next({ path: '/login' })
  } else if (to.fullPath === '/' && from.fullPath === '/') {
    // 第一次进首页 动态路由还没有加载完毕
    return next()
  } else if (!to.matched.length) {
    if (!store.state.auth.prepare) {
      return next()
    } else {
      // 动态路由加载完毕，如果没有匹配到任何的路由，跳转到404页面, 除了首页重定向外,
      return next({ path: '404' })
    }
  } else {
    return next()
  }
})

router.afterEach(to => {
  iView.LoadingBar.finish()
  window.scrollTo(0, 0)
})

export default router
