/**
 * meta: {
 *  title: { String|Number|Function }
 *         显示在侧边栏、面包屑和标签栏的文字
 *         使用'{{ 多语言字段 }}'形式结合多语言使用，例子看多语言的路由配置;
 *         可以传入一个回调函数，参数是当前路由对象，例子看动态路由和带参路由
 *  hideInBread: (false) 设为true后此级路由将不会出现在面包屑中
 *  hideInMenu: (false) 设为true后在左侧菜单不会显示该页面选项
 *  notCache: (false) 设为true后页面在切换标签后不会缓存，如果需要缓存，无需设置这个字段，而且需要设置页面组件name属性和路由配置的name一致
 *  icon: (-) 该页面在左侧菜单、面包屑和标签导航处显示的图标，如果是自定义图标，需要在图标名称前加下划线'_'
 *  beforeCloseName: (-) 设置该字段，则在关闭当前tab页时会去'@/router/before-close.js'里寻找该字段名对应的方法，作为关闭前的钩子函数
 * }
 */
export default [{
        path: '',
        name: 'home',
        meta: {
            title: '首页',
            icon: 'md-home'
        },
        component: () => import('_platform/view/home/Home')
    },
    {
        path: '/payAccount',
        name: 'payAccount',
        meta: {
            icon: 'md-notifications',
            title: '支付账号管理'
        },
        component: () => import('_platform/view/payAccount/PayAccount')
    },
    {
        path: '/wechatList',
        name: 'wechatList',
        meta: {
            icon: 'md-notifications',
            title: '小程序管理'
        },
        component: () => import('_platform/view/wechat/wechatList')
    },
    {
        path: '/merchants',
        name: 'merchants',
        meta: {
            icon: 'md-notifications',
            title: '商户管理'
        },
        component: () => import('_platform/view/permission/merchants/Merchants')
    },
    {
        path: '/editLog',
        name: 'editLog',
        meta: {
            icon: 'md-notifications',
            title: '操作日志'
        },
        component: () => import('_platform/view/editLog')
    },
    {
        path: '/deviceLog',
        name: 'deviceLog',
        meta: {
            icon: 'md-notifications',
            title: '设备日志'
        },
        component: () => import('_platform/view/deviceLog')
    },
    {
        path: '/message',
        name: 'message',
        meta: {
            icon: 'md-notifications',
            title: '消息中心'
        },
        component: () => import('_platform/view/message/Message.vue')
    },
    {
        path: '/merchantMenu',
        name: 'merchantMenu',
        meta: {
            icon: 'md-notifications',
            title: '商户菜单管理'
        },
        component: () => import('_platform/view/permission/merchantsMenu/MerchantsMenu')
    },
    {
        path: '/merchantRole',
        name: 'merchantRole',
        meta: {
            icon: 'md-notifications',
            title: '商户角色管理'
        },
        component: () => import('_c/merchantRole/MerchantRole')
    },
    {
        path: '/merchantUser',
        name: 'merchantUser',
        meta: {
            icon: 'md-notifications',
            title: '商户用户管理'
        },
        component: () => import('_c/merchantUser/MerchantUser')
    },
    {
        path: '/platformMenu',
        name: 'platformMenu',
        meta: {
            icon: 'md-notifications',
            title: '平台菜单管理'
        },
        component: () => import('_platform/view/permission/platformMenu/PlatformMenu')
    },
    {
        path: '/platformRole',
        name: 'platformRole',
        meta: {
            icon: 'md-notifications',
            title: '平台角色管理'
        },
        component: () => import('_c/merchantRole/MerchantRole')
    },
    {
        path: '/platformUser',
        name: 'platformUser',
        meta: {
            icon: 'md-notifications',
            title: '平台用户管理'
        },
        component: () => import('_c/merchantUser/MerchantUser')
    },
    {
        path: '/cpNo',
        name: 'cpNo',
        meta: {
            icon: 'md-notifications',
            title: '充电桩设备ID管理'
        },
        component: () => import('_platform/view/cpNo/CpNo')
    },
    {
        path: '/orderDownload',
        name: 'orderDownload',
        meta: {
            icon: 'md-notifications',
            title: '导出下载'
        },
        component: () => import('_platform/view/orderDownload/orderDownload')
    },
    {
        path: '/deviceMessage',
        name: 'deviceMessage',
        meta: {
            icon: 'md-barcode',
            title: '原始报文'
        },
        component: () => import('_platform/view/deviceMessage/deviceMessage')
    },
    {
        path: '/decryptDeviceMessage',
        name: 'decryptDeviceMessage',
        meta: {
            icon: 'md-barcode',
            title: '解析报文'
        },
        component: () => import('_platform/view/deviceMessage/decryptDeviceMessage')
    },
]
