import Vue from 'vue'
import App from '_platform/App'
import router from '_platform/router'
import store from '_platform/store'
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
import '@/api'
import { directive as clickOutside } from 'v-click-outside-x'
import '_s/index.less'
import 'reset.css'
import '@/assets/icons/iconfont.css'
import 'animate.css'
// 引入quill富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(ViewUI)
Vue.use(VueQuillEditor, /* { default global options } */)
Vue.config.productionTip = false
Vue.directive('clickOutside', clickOutside)

store.dispatch('getMenu')
/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
})
