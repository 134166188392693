import { formStatusEnum } from '_e/index'

/**
 * 模态框的混入文件
 */
export default {
    data () {
        return {
            showDialog: false, // 控制模态框的显示
            showManageDialog: false,
            showRuledetails: false,
        }
    },
    watch: {
        showDialog (value) {
            if (!value) {
                this.$emit('input', value)
            }
        },
        showManageDialog (value) {
            if (!value) {
                this.$emit('input', value)
            }
        },
        showRuledetails (value) {
            if (!value) {
                this.$emit('input', value)
            }
        },
        value (value) {
            if (value) {
                this.showDialog = value
                this.showManageDialog = value
                this.showRuledetails = value
            }
        }
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        formStatus: {
            type: Number,
            required: false,
            default: formStatusEnum.ADD.key
        }
    }
}
