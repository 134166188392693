<template>
  <Layout style="height: 100%" class="main">
    <Sider hide-trigger collapsible :width="200" :collapsed-width="64" v-model="collapsed" class="left-sider">
      <side-menu accordion ref="sideMenu" :active-name="$route.name" :collapsed="collapsed" @on-select="turnToPage"
        :menu-list="menuList">
        <!-- 需要放在菜单上面的内容，如Logo，写在side-menu标签内部，如下 -->
        <div class="logo-con" :style="{overflow: 'hidden', background: $store.state.app.themeColor }">
          <img v-show="!collapsed" :src="maxLogo" key="max-logo" />
          <img v-show="collapsed" :src="minLogo" key="min-logo" />
        </div>
      </side-menu>
    </Sider>
    <Layout>
      <Header class="header-con">
        <header-bar :collapsed="collapsed" @on-coll-change="handleCollapsedChange">
          <user :user-avatar="userAvatar" />
          <theme style="margin: 0 5px;"></theme>
          <fullscreen v-model="isFullscreen" style="margin-right: 10px;" />
          <msg />
        </header-bar>
      </Header>
      <Content class="main-content-con">
        <Layout class="main-layout-con">
          <div class="tag-nav-wrapper">
            <tags-nav :value="$route" @input="handleClick" :list="tagNavList" @on-close="handleCloseTag" />
          </div>
          <Content class="content-wrapper">
            <keep-alive :include="cacheList">
              <router-view style="height: 100%;width: 100%;box-sizing: border-box;" />
            </keep-alive>
            <ABackTop :height="100" :bottom="80" :right="50" container=".content-wrapper"></ABackTop>
          </Content>
        </Layout>
      </Content>
    </Layout>
  </Layout>
</template>
<script>
import SideMenu from '_c/main/components/sideMenu/SideMenu'
import HeaderBar from '_c/main/components/headerBar/HeaderBar'
import TagsNav from '_c/main/components/tagsNav/TagsNav'
import User from '_c/main/components/user/User'
import ABackTop from '_c/main/components/backTop/BackTop'
import Fullscreen from '_c/main/components/fullscreen/Fullscreen'
import Theme from '_c/main/components/theme/Theme'
import Msg from './components/msg/msg'
import { mapMutations, mapState } from 'vuex'
import { getNewTagList, routeEqual, objEqual } from '@/utils'
import minLogo from '@/assets/images/logo-min.jpg'
import maxLogo from '@/assets/images/logo.png'

export default {
  name: 'Main',
  components: {
    SideMenu,
    HeaderBar,
    TagsNav,
    Fullscreen,
    User,
    ABackTop,
    Theme,
    Msg
  },
  data () {
    return {
      collapsed: false,
      minLogo,
      maxLogo,
      isFullscreen: false
    }
  },
  computed: {
    ...mapState({
      tagNavList: (state) => state.auth.tagNavList,
      userAvatar: (state) => state.auth.avatarImgPath
    }),
    cacheList () {
      return ['ParentView', ...this.tagNavList.length ? this.tagNavList.filter(item => !(item.meta && item.meta.notCache)).map(item => item.name) : []]
    },
    menuList () {
      return this.$store.getters.menuList
    }
  },
  methods: {
    ...mapMutations([
      'setBreadCrumb',
      'setTagNavList',
      'addTag',
      'closeTag'
    ]),
    turnToPage (route) {
      let { name, params, query } = {}
      // 当路由为页面配置为页面跳转
      if (typeof route === 'string') {
        name = route
      } else {
        // 如果为当前页面, 如果参数不一样，允许执行
        if (this.$route.name === route.name &&
          objEqual(this.$route.query, route.query) &&
          objEqual(this.$route.params, route.params)) {
          return
        }
        name = route.name
        params = route.params
        query = route.query
      }
      if (name.indexOf('isTurnByHref_') > -1) {
        window.open(name.split('_')[1])
        return
      }
      this.$router.push({
        name,
        params,
        query
      })
    },
    handleCollapsedChange (state) {
      this.collapsed = state
    },
    handleCloseTag (res, type, route) {
      if (type !== 'others') {
        if (type === 'all') {
          this.turnToPage(this.$store.state.auth.indexPage)
        } else {
          if (routeEqual(this.$route, route)) {
            this.closeTag(route)
          }
        }
      }
      this.setTagNavList(res)
    },
    handleClick (item) {
      this.turnToPage(item)
    }
  },
  watch: {
    '$route' (newRoute) {
      const { name, query, params, meta } = newRoute
      this.addTag({
        route: { name, query, params, meta },
        type: 'push'
      })
      this.setBreadCrumb(newRoute)
      this.setTagNavList(getNewTagList(this.tagNavList, newRoute))
      this.$refs.sideMenu.updateOpenName(newRoute.name)
    }
  },
  mounted () {
    /**
     * @description 初始化设置面包屑导航和标签导航
     */
    this.setTagNavList()
    const { name, params, query, meta } = this.$route
    this.addTag({
      route: { name, params, query, meta }
    })
    this.setBreadCrumb(this.$route)
    // 如果当前打开页面不在标签栏中，跳到homeName页
    if (!this.tagNavList.find(item => item.name === this.$route.name)) {
      this.$router.push({
        name: this.$store.state.auth.indexPage
      })
    }
  }
}
</script>
<style lang="less" scoped>
.main {
  .logo-con {
    height: 64px;
    padding: 10px;

    img {
      height: 44px;
      width: auto;
      display: block;
      margin: 0 auto;
    }
  }

  .header-con {
    background: #fff;
    padding: 0 20px;
    width: 100%;
  }

  .main-layout-con {
    height: 100%;
    overflow: hidden;
  }

  .main-content-con {
    height: ~'calc(100% - 60px)';
    overflow: hidden;
  }

  .tag-nav-wrapper {
    padding: 0;
    height: 40px;
    background: #f0f0f0;
  }

  .content-wrapper {
    padding: 18px;
    height: ~'calc(100% - 80px)';
    overflow: auto;
  }

  .left-sider {
    .ivu-layout-sider-children {
      overflow-y: scroll;
      margin-right: -18px;
    }
  }
}
.side-menu-wrapper {
  overflow-y: auto;
}
.side-menu-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.ivu-menu-item > i {
  margin-right: 12px !important;
}

.ivu-menu-submenu > .ivu-menu > .ivu-menu-item > i {
  margin-right: 8px !important;
}

.collased-menu-dropdown {
  width: 100%;
  margin: 0;
  line-height: normal;
  padding: 7px 0 6px 16px;
  clear: both;
  font-size: 12px !important;
  white-space: nowrap;
  list-style: none;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  &:hover {
    background: rgba(100, 100, 100, 0.1);
  }

  & * {
    color: #515a6e;
  }

  .ivu-menu-item > i {
    margin-right: 12px !important;
  }

  .ivu-menu-submenu > .ivu-menu > .ivu-menu-item > i {
    margin-right: 8px !important;
  }
  .collapsed-menu i {
    color: #fff;
  }
}

.ivu-select-dropdown.ivu-dropdown-transfer {
  max-height: 400px;
}
/deep/ .ivu-layout-sider {
  background: #fff;
}
/deep/ .ivu-layout-sider-collapsed {
  background: #515a6e;
}
</style>
