<template>
    <div class="user-avatar-dropdown">
        <merchant-dialog v-model="showDialog"></merchant-dialog>
        <Dropdown @on-click="handleClick">
            <Badge>
                <Avatar :src="userAvatar"/>
            </Badge>
            <Icon :size="18" type="md-arrow-dropdown"></Icon>
            <DropdownMenu slot="list">
                <DropdownItem name="logout">退出登录</DropdownItem>
                <DropdownItem name="merchantList" v-if="!isPlatform">商家列表</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    </div>
</template>

<script>
    import MerchantDialog from '_merchant/view/merchantList/MerchantDialog'
    import modeJudgeMixin from '_m/modeJudge.mixin'
    export default {
        name: 'User',
        mixins: [modeJudgeMixin],
        components: {
            MerchantDialog
        },
        data() {
            return {
                showDialog: false,
            }
        },
        props: {
            userAvatar: {
                type: String,
                default: ''
            },
            messageUnreadCount: {
                type: Number,
                default: 0
            }
        },
        methods: {
            logout () {
                this.$store.dispatch('logout')
            },
            handleMerchantList() {
                this.showDialog = true
            },
            handleClick (name) {
                switch (name) {
                    case 'logout':
                        this.logout()
                        break
                    case 'merchantList':
                        this.handleMerchantList()
                        break
                    default:
                        break
                }
            }
        }
    }
</script>
<style lang="less" scoped>
    .user {
        &-avatar-dropdown {
            cursor: pointer;
            display: inline-block;
            // height: 64px;
            vertical-align: middle;
            // line-height: 64px;
            .ivu-badge-dot {
                top: 16px;
            }
        }
    }
</style>
