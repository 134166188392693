var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      class: ["sider-trigger-a", _vm.collapsed ? "collapsed" : ""],
      attrs: { type: "text" },
      on: { click: _vm.handleChange }
    },
    [_c("Icon", { attrs: { type: _vm.icon, size: _vm.size } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }